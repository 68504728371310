/** @define PnCheckbox; use strict */

/* A Hack to overcome .reset rules */
.PnCheckbox.PnCheckbox {
  @apply cursor-pointer;

  font-family: var(--font-family);

  .PnCheckbox-field {
    @apply align-middle
      bg-white
      border-2
      border-solid
      border-grey-dark
      box-border
      flex-shrink-0
      relative
      rounded
      select-none
      text-primary-dark
      mb-0
      mx-0
      mt-1;

    height: 1.5rem;
    width: 1.5rem;
    background-origin: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    outline: none;
    transition: background-color 250ms ease-out;

    &:checked {
      @apply bg-center
        bg-no-repeat
        border-transparent;

      background-color: currentColor;
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 12' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ctitle%3ePath%3c/title%3e%3cpath d='M5.714 11.556L0 6.01l1.611-1.564 4.103 3.97L14.39 0 16 1.575z'/%3e%3c/svg%3e");
      background-size: 70%;
    }

    &:focus {
      box-shadow: 0 0 0 8px rgba(0, 184, 240, 0.08);
    }

    &.is-error {
      @apply border-danger;
    }

    &:disabled {
      @apply bg-grey-light
        border-grey-light;
    }
  }
}
