/**
  Minimal reset from Tailwind CSS suitable for PN elements.
  Don't change it until it's critical to do so.

  The problem: we can't directly rely on `@tailwind base;`
  as without Shadow DOM it will introduce style conflicts.

  It's used in `tailwind.css`.
*/

*,
::before,
::after {
  box-sizing: border-box !important;
  border-width: 0;
  border-style: solid;
}

textarea {
  resize: vertical;
  overflow: auto;
}

input::placeholder,
textarea::placeholder {
  color: rgba(25, 25, 22, 0.38);
}

button,
input,
optgroup,
select,
textarea,
fieldset {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[role='button'] {
  cursor: pointer;
}

button {
  background-color: transparent;
  background-image: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[hidden] {
  display: none;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

progress {
  vertical-align: baseline;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

svg {
  pointer-events: none;
}

img,
video {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: inherit;
  background-color: transparent;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

blockquote,
dl,
dd,
hr,
figure,
p,
pre,
details {
  margin: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

hr {
  border-top-width: 1px;
  height: 0;
  overflow: visible;
}

summary {
  display: list-item;
}

/* Devise overrides */
.field_with_errors {
  width: 100%;

  .PnInput-field {
    border-color: var(--pn-danger) !important;
    background-color: var(--pn-danger-lightest);
  }
}
