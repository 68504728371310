/** @define PnButton; use strict */

.PnButton {
  appearance: none;
  background-color: var(--pn-primary-dark);
  border: 1px transparent solid;
  border-radius: 0.25rem;
  margin: 0.5rem 0;
  padding: 0.6rem 2.625rem;
  color: white;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  min-height: 2.75rem;
  min-width: 100%;
  text-transform: uppercase;
  transition: 175ms background-color ease;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  cursor: pointer;

  &:focus,
  &:active {
    background-color: var(--pn-primary-darker);
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  /* States */

  &.is-expanded {
    width: 100%;
  }

  &.is-disabled {
    background-color: var(--pn-grey-lighter) !important;
    color: var(--pn-grey) !important;
    cursor: default;

    &.PnButton--outline {
      border-color: var(--pn-grey) !important;
      background-color: transparent !important;
    }

    &:focus,
    &:active {
      background-color: var(--pn-grey-lighter) !important;
    }
  }

  &.is-loading {
    padding-top: 0;
    padding-bottom: 0;
    height: 2.75rem;
    align-items: flex-start;
  }

  &.is-square {
    @apply min-h-0 p-0;
  }

  /* Modifiers */
  &--small {
    padding: 0.5rem 1.4rem;
    min-height: 2.25rem;
    font-size: 0.875rem;

    &.is-loading {
      height: 2.25rem;

      .PnSpinner--mini {
        height: 2.1rem;
      }
    }
  }

  &--outline {
    background-color: transparent;
    color: var(--pn-primary);
    border-color: var(--pn-primary);

    &:focus,
    &:active {
      color: var(--pn-primary-darker);
      border-color: var(--pn-primary-darker);
      background-color: var(--pn-primary-lightest);
    }
  }
}
