/** @define PnInput; use strict */

.PnInput {
  font-family: var(--font-family);

  &--withIconStart .PnInput-field.PnInput-field {
    padding-left: 46px;
  }

  &--withIconEnd .PnInput-field.PnInput-field {
    padding-right: 46px;
  }

  &--withFieldPrefix .PnInput-field.PnInput-field {
    @apply border-l-0
      rounded-tl-none
      rounded-bl-none;
  }

  &--withFieldSuffix .PnInput-field.PnInput-field {
    @apply border-r-0
      rounded-tr-none
      rounded-br-none;
  }

  &-fieldPrefix,
  &-fieldSuffix {
    @apply bg-grey-lighter
      font-bold
      text-sm
      text-grey-dark
      border-solid
      border-grey;

    padding: 0.8125rem;
    line-height: 1.125rem;
    height: 2.75rem;
    white-space: nowrap;
  }

  &-fieldPrefix {
    order: 0;

    @apply rounded-tl
      rounded-bl;
  }

  &-fieldSuffix {
    @apply rounded-tr
      rounded-br;

    order: 2;

    & ~ .PnInput-field.PnInput-field {
      @apply border-r-0
        rounded-tr-none
        rounded-br-none;
    }
  }
}

/* A Hack to overcome .reset rules */
.PnInput-field.PnInput-field {
  @apply border
    border-solid
    border-grey
    rounded
    text-grey-darker
    text-base
    w-full;

  padding: 0.5625rem 1rem;
  height: 2.75rem;
  line-height: 1.5rem;
  transition: 200ms border-color ease;
  order: 1;

  &:focus {
    border-color: var(--pn-primary-dark);
    outline: none;

    ~ .PnInput-fieldPrefix,
    ~ .PnInput-fieldSuffix {
      border-color: var(--pn-primary-dark);
    }
  }

  &.is-error {
    border-color: var(--pn-danger);
    background-color: var(--pn-danger-lightest);

    ~ .PnInput-fieldPrefix,
    ~ .PnInput-fieldSuffix {
      border-color: var(--pn-danger) !important;
    }
  }

  &:disabled {
    background-color: var(--pn-grey-lighter);
    border-color: var(--pn-grey-light);
    resize: none;
  }
}

.PnInput-icon.PnInput-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--pn-grey);
  transition: 200ms color ease;

  &--end {
    left: auto;
    right: 1rem;
  }

  svg {
    fill: currentColor;
    width: 100%;
  }
}
